/* .cardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    margin: 10px;
    width: 80%; /* Adjust based on your layout 
  }
  
  .profileImage {
    width: 50px; /* Adjust based on your design 
    height: 50px;
    border-radius: 10%;
    margin-right: 15px;
  }
  
  .cardDetails {
    flex-grow: 1;
  }
  
  .name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .rating {
    color: #ffc107; /* Gold color for stars 
    font-size: 16px;
  }
  
  .confirmButton {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .confirmButtonHover {
    background-color: #45a045; /* Slightly darker green on hover 
  } */


  .cardContainer {
    /* width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    place-items: center; */

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 546px;
    margin: auto;
    background: #F5F5F5;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: 'Arial', sans-serif;
  }
  
  .profileImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .cardDetails {
    text-align: center;
  }
  
  .expandedDetails {
    border-top: 1px solid #eee;
    padding-top: 10px;
  }

  .subjects, .time, .price, .location{
    display: flex;
    justify-content: space-between; /* Spreads children across the full width of the container */
    width: 100%; /* Ensure the container takes full available width */
    padding-right: 20px;
    text-align: right;
}

.subjects b, .time b, .price b, .location b {
    color: black;
    padding-left: 20px;
    /* The 'justify-content: flex-start' is not valid here since it's not a flex container itself. */
}

/* Ensure other content within the containers (if not <b>) is aligned to the right */
.subjects > :not(b), .time > :not(b), .price > :not(b) {
    margin-left: auto; /* Pushes non-bold elements to the right */
}

  .confirmButton, .declineButton {
    padding: 5px 10px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirmButton {
    background-color: #4CAF50;
    color: white;
  }
  
  .declineButton {
    background-color: #f44336;
    color: white;
  }
  
  