/* .info {
  /* padding: 1rem; */
  /* border-left: var(--thick) solid var(--secondary-dark);
  box-shadow: -4px -10px 5px var(--alm-light);
}
.time_box {
  background-color: var(--secondary);
  color: var(--light) !important;
  border-radius: var(--rad-md);
  min-width: 0;
}

.pos_link {
  text-decoration: underline;
}
.map_marker {
  color: var(--secondary);
} */

/* 
.cardContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-width: 546px;
  margin: auto;
  background: #F5F5F5;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Arial', sans-serif;
}

.profileImage{
  width: 50px; /* Adjust width as needed 
  height: 50px; /* Adjust height as needed 
  border-radius: 50%; /* Makes the image round 
  margin-right: 10px; 
}

.name, .rating {
  display: flex;
  flex-direction: column;
  margin: 0;
}

/* Ensure other content within the containers (if not <b>) is aligned to the right 
.subjects > :not(b), .time > :not(b), .price > :not(b) {
  margin-left: auto; /* Pushes non-bold elements to the right 
}
.subjects, .time, .price, .location{
  display: flex;
  justify-content: space-between; /* Spreads children across the full width of the container 
  width: 100%; /* Ensure the container takes full available width 
  padding-right: 20px;
  text-align: right;
}
.subjects b, .time b, .price b, .location b {
  color: black;
  padding-left: 20px;
  /* The 'justify-content: flex-start' is not valid here since it's not a flex container itself. 
} */


/* .cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; 
  max-width: 546px;
  margin: auto;
  background: #F5F5F5;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  padding: 10px;
}

.profileSection {
  display: flex;
  align-items: center;
  width: 100%;
}

.profileImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
}

.name, .rating {
  margin: 0;
}

.additionalInfo {
  width: 100%;
  padding-top: 10px; 
}

.location, .time, .time_box {
  display: flex;
  justify-content: flex-end; 
  width: 100%; 
  padding-right: 20px;
}

.location > *, .time > * , .time_box > * {
  text-align: right; 
  margin-left: 10px;
}

.cardDetails {
  display: flex;        
  flex-direction: row;  
  justify-content: center; 
  align-items: center;
} */




.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; 
  max-width: 546px;
  margin: auto;
  background: #F5F5F5;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  padding: 10px;
  margin-bottom: 10px;
}

.profileSection {
  display: flex;
  align-items: center;
  width: 100%;
}

.profileImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
}

.name, .rating {
  margin: 0;
}

.cardDetails {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-end; /* Aligns children to the start of the container */
  width: 100%; /* Full width to align with other sections if needed */
}

.location, .time, .time_box {
  display: flex;
  justify-content: flex-end; /* Aligns content to the left */
  width: 100%; /* Full width to align within the container */
  padding-right: 20px;
}

.location > *, .time > * , .time_box > * {
  text-align: right;
  margin-left: 10px;
}

