.root {
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  background-color: #f7f7f7;
  height: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.body {
  flex-grow: 1;
  padding-block-end: 40px;
}
