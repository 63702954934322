.top_search_bar {
  background-color: var(--primary);
  width: min(80%, 30rem);
  border-radius: var(--rad-md);
}

@keyframes fade-in-opacity {
  from {opacity: 0;}
  to {opacity: 1;}
}

.choose-subjects-section {
  margin: auto;
  min-height: 0;
  min-width: 0;
  animation: fade-in-opacity 400ms ease-in-out;
}

.choose-subjects-section .background_board {
  background-color: var(--secondary);
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.choose-subjects-section .whiteboard {
  background-color: var(--primary);
  border-radius: var(--rad-md);
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

.choose-subjects-section .whiteboard .filler {
  width: 100%;
  height: 100vw;
}

.choose-subjects-section .scrollParent {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.subject_category {
  /* Constants */
  --subject-size: 40vw;
  --grid-width: 90vw;
  --grid-gap: clamp(0.7rem, 2vw, 1rem);

  z-index: 1;
  display: flex;
  grid-gap: var(--grid-gap);
  margin: 0 clamp(1rem, 4vw, 2rem);
  margin-top: 0.2rem;
}

.subject_category .subject-option {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  width: min-content;
}

.subject-option .subjIcon {
  position: relative;
  background-color: var(--secondary);
  color: var(--secondary-light);
  border-radius: var(--rad-md);
  width: clamp(10rem, 20vw, 15rem);
  height: clamp(10rem, 20vw, 15rem);
  transition-duration: 150ms;
  transition-property: color, background-color;
}

.subject-option.active .subjIcon {
  background-color: var(--secondary-light);
  color: var(--secondary);
}

.subjCheck {
  color: var(--secondary);
  position: absolute;
  top: 5%;
  right: 5%;
}

.choose-subjects-section .next-btn {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 5%;
  margin: 0;
  background-color: transparent;
  color: var(--secondary-dark);
  border: none;
}

.mapContainaer {
  width: var(--large-w);
  height: var(--tall-h);
}

.mapBox {
  opacity: 0.7;
  background-color: var(--secondary-dark);
  color: var(--light);
  border-radius: var(--rad-sm);
}

.saved_loc_parent {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 8px;
}

.saved_loc {
  border-radius: var(--rad-md);
  background-color: var(--secondary-dark);
  color: var(--secondary);
  height: var(--min-h);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  font-family: "Roboto";
  font-weight: 500;
  padding: 0.8rem;
}

.nxt_btn_parent {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.nxt_btn {
  background-color: var(--secondary) !important;
  color: var(--light) !important;
  border-radius: var(--rad-lg) !important;
  z-index: 5;
}

.next_button {
  color: #01613b !important;
  font-size: 1rem !important;
  padding: 9px 40px !important;
  border-radius: 20px !important;
  background-color: #01cc7b !important;
  height: min-content !important;
}

.address_text {
  color: #2a2a2a !important;
  width: 100% !important;
  font-weight: 500 !important;
  padding-bottom: 2px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding-bottom: 1px !important;
}

.coordinates_text {
  color: #959595 !important;
  width: max-content !important;
  font-size: 0.7rem !important;
  padding-bottom: 1px !important;
}

.loading_placeholder {
  animation: opacity_enter 300ms;
}

@keyframes opacity_enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.position_select_root {
  position: absolute;
  bottom: 4%;
  width: 100vw;
  display: flex;
  justify-content: center;
  transition: bottom 300ms;
}

.position_select_size_wrapper {
  width: min(max-content, 100vw);
  min-width: min(90%, 26rem);
  max-width: 98%;
}

.position_select_panel {
  background-color: #fcfaf9;
  padding: 17px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(0 0 0 / 16%) 0 0 14px -2px;
  column-gap: 10px;
  max-width: 100vw;
  min-width: min(26rem, 100%);
}

.position_select_info {
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  min-width: 0;
  flex-grow: 1;
}

.category_wrapper:last-child {
  margin-bottom: 0 !important;
}

.subject_header_skeleton {
  margin-top: 2rem;
  margin-left: clamp(1rem, 4vw, 2rem);
  margin-bottom: 1rem;
}
