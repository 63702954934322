.cardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 546px;
    margin: auto;
    background: #F5F5F5;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: 'Arial', sans-serif;
  }

/* Ensure other content within the containers (if not <b>) is aligned to the right */
.subjects > :not(b), .time > :not(b), .price > :not(b) {
    margin-left: auto; /* Pushes non-bold elements to the right */
}
.subjects, .time, .price, .location{
    display: flex;
    justify-content: space-between; /* Spreads children across the full width of the container */
    width: 100%; /* Ensure the container takes full available width */
    padding-right: 20px;
    text-align: right;
}
.subjects b, .time b, .price b, .location b {
    color: black;
    padding-left: 20px;
    /* The 'justify-content: flex-start' is not valid here since it's not a flex container itself. */
}