.schedule {
  width: 100%;
  /* border: var(--thick-md) solid var(--joker); */
}

.schedule {
  width: 100%;
  /* height: var(--tall-h);
    overflow-y: scroll; */
}
