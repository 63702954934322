
.root	{
  /* display: flex; */
}

.navbar {
  box-shadow: 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  display: flex;
  padding: 10px 16px;
  justify-content:space-around;
  list-style: none;
  column-gap: 10px;
  position: relative;
  background-color: white;
}
.navLink	{
  padding: 12px 0;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: rgba(110, 139, 123, 0.459) !important;
}

.navLink:hover	{
  background-color:rgba(241, 241, 241, 0.747);
}

.navItem	{
  flex-grow: 1;
  max-width: 120px;
}
.activeNavLink	{
  background-color: rgba(41, 126, 76, 0.171) !important;
  color: rgba(43, 161, 92, 0.884) !important;
}