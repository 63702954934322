.root {
  /* background-color: var(--primary); */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.inputContainer {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}
