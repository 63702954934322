.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh !important;
  width: 100vw !important;
}

.content {
  flex-grow: 1;
  flex-basis: 0;
  min-height: 0;
  position: relative;
  /* max-height: 80vh; */
}

.scrollparent {
  overflow-y: scroll;
  height: 100%;
}
