.inputContainer {
  max-width: 500px;
  margin: 0 auto;
}

@keyframes loginLogoSpin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title	{
  font-size: 82px !important;
  font-weight: 600 !important;
  color: rgb(151, 155, 151);
  padding-block-end: 24px;
  letter-spacing: 3px !important;

  /* Set breakpoint for mobile */
  @media (max-width: 768px) {
    font-size: 64px !important;
  }
}