
.text	{
    font-size: 1.3rem !important;
    font-weight: 600 !important;
}
.logo	{
    width: 32px;
    transform: scale(1.1) translate(-1px, 0px);
}
.root	{
    cursor: pointer;
}
.text-white	{
    color: white;
}
.logo-white    {
    filter: brightness(100%);
}
.text-gray	{
}
.logo-gray    {
    filter: brightness(55%);
}
.text-green    {
    color: #00613B;
}
.logo-green    {
    filter: invert(85%) sepia(53%) saturate(3200%) hue-rotate(139deg) brightness(121%) contrast(106%)
}