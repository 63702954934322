.parent {
  background-color: var(--secondary);
  z-index: 10;
  flex: 0 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.banner {
  background-color: var(--secondary); /* The banner's background color */
  color: white;
  padding: 10px 20px; /* Or as needed for your specific design */
  text-align: center;
  display: flex;
  justify-content: flex-start; /* Center children horizontally */
  align-items: center; /* Center children vertically */
}

.logo {
  /* If you have a logo to include in the banner */
  width: auto; /* Adjust as needed */
  height: 50px;
  margin-right: 10px;
}

.topPadding {
  padding-top: 1.5rem;
}

.parent nav {
  background-color: inherit;
}

.parent * {
  z-index: 10 !important;
}

.navbar {
  padding: 10px 0;
  box-shadow: none;
}

/* For Rounded corners at top */
.decoration_edge {
  width: 100%;
  background-color: var(--primary);
  margin-top: 0;
  position: relative;
}

.background_board {
  position: absolute;
  top: 0;
  background-color: var(--secondary);
  width: 100%;
  height: var(--rad-md);
  z-index: 9;
}

.background_board::before,
.background_board::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -50px;
  height: 50px;
  width: var(--rad-md);
  box-shadow: 0 -25px 0 0 var(--secondary);
}

.background_board::before {
  border-top-left-radius: var(--rad-md);
  left: 0;
}

.background_board::after {
  border-top-right-radius: var(--rad-md);
  right: 0;
}

.bottom_box {
  z-index: 11;
}

.back_button {
  position: absolute;
  width: 26px !important;
  height: 26px !important;
  cursor: pointer;
  margin-top: 1rem;
  margin-left: 1rem;
  background-color: var(--primary);
  border-radius: 50%;
  padding: 12px;
}

.help_button {
  color: #00613b;
  margin: auto 0;
  width: 1.8rem !important;
  height: 1.8rem !important;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0;
}
