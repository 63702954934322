.root {
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.inputContainer	{
  max-width: 500px;
  margin: 0 auto;
}